<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>信息管理</el-breadcrumb-item>
      <el-breadcrumb-item>信息列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 视图区 -->
    <el-card>
      <el-row>
        <el-button type="primary"
                   class="addbtn"
                   @click="showAddDialog">添加信息</el-button>
      </el-row>

      <!-- 分类列表 -->
      <el-table :data="showList"
                style="width: 100%;margin-bottom: 20px;"
                row-key="id"
                stripe
                default-expand-all
                :tree-props="{children: 'children'}">
        <el-table-column type="index"
                         label="#"></el-table-column>
        <el-table-column prop="name"
                         label="分类名称"></el-table-column>
        <el-table-column prop="level"
                         label="等级">
          <template slot-scope="scope">
            <el-tag size="mini"
                    v-if="scope.row.level === 0">一级</el-tag>
            <el-tag type="success"
                    size="mini"
                    v-else-if="scope.row.level === 1">二级</el-tag>
            <el-tag type="wraning"
                    size="mini"
                    v-else>三级</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="status"
                         label="是否显示">
          <template slot-scope="scope"
                    v-if="scope.row.level !== 0">
            <el-switch v-model="scope.row.status"
                       :active-value="1"
                       :inactive-value="0"
                       @change="stateChange(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         width="200">
          <template slot-scope="scope">
            <el-button type="primary"
                       icon="el-icon-edit"
                       size="mini"
                       @click="goEditPage(scope.row.id)">编辑</el-button>
            <el-button type="warning"
                       icon="el-icon-delete"
                       size="mini"
                       @click="deleteById(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 添加分类 -->
    <el-dialog title="添加信息分类"
               :visible.sync="addVisible"
               v-if="addVisible"
               :close-on-click-modal="false"
               width="50%"
               @close="addDialogClose">
      <!-- 添加分类表单区 -->
      <el-form :model="addForm"
               :rules="addFormRules"
               ref="addFormRef"
               label-position="top">
        <el-form-item label="分类名称："
                      prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>

        <!-- 分类选择 -->
        <el-form-item label="上级分类：">
          <el-cascader v-model="selectedKeys"
                       :options="parentCateDate"
                       :props="{ expandTrigger: 'hover', value: 'id', label: 'name', children: 'children', checkStrictly: true }"
                       :placeholder="placeholder"
                       clearable
                       @change="handleCheckChange"></el-cascader>
        </el-form-item>

      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="upDate">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showList: [],
      // 添加对话框
      addVisible: false,

      // 添加分类的表单数据对象
      addForm: {
        name: '',
        pid: 0,
        level: 0
      },
      // 添加分类表单校验
      addFormRules: {
        name: [
          { required: true, message: '请输入产品分类名称', trigger: 'blur' }
        ]
      },
      // 父级分类列表信息
      parentCateDate: [],

      // 选中的父级数组
      selectedKeys: [],

      // 级联选择器占位符
      placeholder: '请选择所属分类'
    }
  },

  methods: {
    async getShowList () {
      const { data: res } = await this.$http.get('company')
      if (res.code !== 200) {
        return this.$message.error('网络延时，请重新刷新网页重试...')
      }
      this.showList = res.data
    },

    // 改变状态
    async stateChange (userinfo) {
      const { data: res } = await this.$http.put(`company/${userinfo.id}?status=${userinfo.status}`)
      if (res.code !== 200) {
        userinfo.status = !userinfo.status
        return this.$message.error('更新状态失败！')
      }
      this.$message.success('更新状态成功！')
      // 刷新列表
      this.getShowList()
    },

    // 添加分类按钮
    showAddDialog () {
      this.getParentCateList()
      this.addVisible = true
    },

    // 获取父级分类数据
    async getParentCateList () {
      const { data: res } = await this.$http.get('company')
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.parentCateDate = res.data
    },

    // 添加表单分类选择器触发函数
    handleCheckChange () {
      // 如果 this.selectedKeys 数组中的 length 大于0，则选中父级分类
      // 反之，就说明没有选中任何父级分类
      if (this.selectedKeys.length > 0) {
        this.addForm.pid = this.selectedKeys[this.selectedKeys.length - 1]
        this.addForm.level = this.selectedKeys.length
      } else {
        this.addForm.pid = 0
        this.addForm.level = 0
      }
    },

    // 关闭时弹窗，清空数据
    addDialogClose () {
      this.$refs.addFormRef.resetFields()
      this.selectedKeys = []
      this.addForm.level = 0
      this.addForm.pid = 0
    },

    // 发送添加请求
    upDate () {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post('company', this.addForm)
        if (res.code !== 200) {
          return this.$message.error('网络延时，请刷新页面重试...')
        }
        this.$message.success('添加分类成功！')
        this.getShowList()
        this.addVisible = false
      })
    },

    // 删除分类信息
    async deleteById (id) {
      const confirmResult = await this.$confirm('您确定要删除这个分类吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }
      ).catch(err => err)

      // 如果用户取消了删除
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除！')
      }

      const { data: res } = await this.$http.delete('company/' + id, {
        headers: { Authorization: localStorage.getItem('token') }
      })
      if (res.code !== 200) {
        return this.$message.error('分类删除失败，请重试...')
      }
      this.$message.success('成功删除分类')
      this.getShowList()
    },

    goEditPage (rowid) {
      this.$router.push(
        { path: '/class/edit/' + rowid }
      )
    }
  },

  created () {
    this.getShowList()
  }
}
</script>

<style scoped>
.el-cascader {
  width: 100%;
}

.mycascader .el-radio__inner {
  top: -18px;
  left: -19px;
  border-radius: 0;
  border: 0;
  width: 170px;
  height: 34px;
  background-color: transparent;
  cursor: pointer;
  box-sizing: border-box;
  position: absolute;
}

.mycascader .el-radio__input.is-checked .el-radio__inner {
  background: transparent;
}
</style>
